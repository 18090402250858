



















































































































import { AccountService } from '@/code/services/user/accountService';
import { EventBus } from '@/code/util/eventBus';
import { Confirm, Loading } from '@/code/util/swalUtil';
import Swal from 'sweetalert2';
import Vue from 'vue';

export default Vue.extend({

  data() {
    return {
      idAccount: 0,
      logoUrl: '',
      layoutUrl: '',
      layoutImage: new Blob(),
      newLayoutImage: '',
    }
  },

  mounted(): void {
    try {
      this.idAccount = parseInt(this.$route.params.id);
    } catch (ex: any) {
      this.idAccount = 0;
    }
    const that = this;
    if (this.idAccount && this.idAccount > 0) {
      this.loadAccount();
    } else {
      Swal.fire({
        title: 'Parâmetro inválido',
        text: 'A loja informada é invalida!',
        icon: 'error'
      })
      .then(
        (result) => {
          that.$router.replace({ name: 'assistentAction' });
        }
      )
    }
  },

  methods: {
    loadAccount: async function() {
      try {
        Loading.fire("Carregando layout");
        const service = new AccountService();
        const account = await service.get(this.idAccount, true);

        this.logoUrl = account.detail.logo;
        this.layoutUrl = account.detail.layout;
        Swal.close();
      } catch {
        //do nothing
      }
    },
    resetLayout: function() {
      alert('Funcionalidade não implementada');
    },
    changeImage: function() {
      alert('Funcionalidade não implementada');
      // if (this.$refs.layout) {
      //   const layout = this.$refs.layout as any;
      //   layout.click();
      // }
    },
    fileUploaded: function(event) {
      Loading.fire("Preparando imagem!");
      this.layoutImage = (this.$refs.layout as any).files[0];
      const that = this;
      var reader = new FileReader();
      reader.onloadend = function() {
        if (reader.result) {
          that.newLayoutImage = reader.result as string;
          Swal.close();
        }
      }
      reader.readAsDataURL(this.layoutImage);
    },
    cancelImage: function() {
      this.layoutImage = new Blob();
      this.newLayoutImage = '';
    },
    upload: async function() {
      const service = new AccountService();
      const result = service.uploadLayout(this.layoutImage);
    },
    previousTab: async function() {
      if (this.layoutImage.size > 0) {
        Confirm.fire('Os dados informados serão perdidos. Deseja voltar à etapa anterior?')
        .then(result => {
          if (result && result.isConfirmed) {
            EventBus.$emit('previousAssistentRoute');
          }
        });
      } else {
        EventBus.$emit('previousAssistentRoute');
      }
    },
    nextTab: async function() {
      if (this.layoutImage && this.layoutImage.size > 0) {
        Confirm.fire('Os dados informados serão perdidos. Deseja ir à próxima etapa?')
        .then(result => {
          if (result && result.isConfirmed) {
            EventBus.$emit('previousAssistentRoute');
          }
        });
      } else {
        EventBus.$emit('nextAssistentRoute');
      }
    }
  }

})
